import {
  Button,
  Chip, Drawer, InfoState, Panel, ScrollArea, Separator, Skeleton, Stack, Text,
  useResponsiveContext,
} from '@lualtek/react-components';
import { AnimatePresence } from 'motion/react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useEventListenerRef, useMergeRefs } from 'rooks';
import { match } from 'ts-pattern';

import { useUser } from '@/api/user';
import {
  LumaBalloon, LumaInput, LumaLogo, LumaNoTokens, LumaPresets,
} from '@/components/luma/shared';
import { useInfoContext } from '@/context/use-info-context';
import { useLumaContext } from '@/context/use-luma-context';
import { useTranslate } from '@/core/i18n';
import { useLumaLogic } from '@/core/luma-helpers/use-luma-logic';

import styles from './luma.module.css';

export type LumaProps = {
  loading?: boolean;
}

const SCROLL_THRESHOLD = 450;

export const Luma: FCChildren<LumaProps> = ({
  loading,
  children,
  ...otherProps
}) => {
  const { user } = useUser();
  const { info } = useInfoContext();
  const { t } = useTranslate();
  const [isShowJumpButton, setIsShowJumpButton] = useState(false);
  const messagesRef = useRef<HTMLDivElement>(null);
  const messagesRefScroll = useEventListenerRef('scroll', () => {
    if (messagesRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
      setIsShowJumpButton(scrollTop + clientHeight < scrollHeight - SCROLL_THRESHOLD);
    }
  });
  const refs = useMergeRefs(messagesRef, messagesRefScroll);

  const {
    messages,
    hasMessages,
    input,
    handleInputChange,
    handleSubmit,
    append,
    queryPresets,
    shouldShowAllPresets,
    setShouldShowAllPresets,
    isLoading,
    stop,
    isHistoryLoading,
    hasOrganizationChatTokens,
  } = useLumaLogic({
    userId: user?.id ?? 0,
    organizationId: info?.currentOrganizationId ?? 0,
  });
  const { isLumaOpened, setIsLumaOpened } = useLumaContext();
  const { matches } = useResponsiveContext();

  const handleJumpToBottom = useCallback(() => {
    messagesRef.current?.scrollTo({ top: messagesRef.current.scrollHeight, behavior: 'smooth' });
  }, []);

  /**
   * Handling scroll to bottom when:
   * - messages are updated
   * - screen size is extra large
   * - Luma is opened
   * */
  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [
    messages,
    matches.extraLarge,
    isLumaOpened,
  ]);

  useEffect(() => {
    if (!isLumaOpened) {
      setShouldShowAllPresets(false);
    }
  }, [isLumaOpened, setShouldShowAllPresets]);

  const InfoStateActions = useMemo(() => match({
    hasQueryPresets: queryPresets.length > 0,
    hasTokens: hasOrganizationChatTokens,
  }).with({ hasQueryPresets: true, hasTokens: true }, () => (
    <LumaPresets
      presets={queryPresets}
      visiblePresets={4}
      onClick={async query => append({ role: 'user', content: query })}
      shouldShowAllPresets={shouldShowAllPresets}
      onExpand={() => setShouldShowAllPresets(true)}
    />
  )).with({ hasTokens: false }, () => (
    <LumaNoTokens />
  )).otherwise(() => null),
  [queryPresets, hasOrganizationChatTokens, append, shouldShowAllPresets, setShouldShowAllPresets]);

  const LumaCore = useMemo(() => (
    <Stack vAlign="space-between" fill={false} className={styles.Luma} {...otherProps}>
      <Stack
        className={styles.ChatWrapper}
        vPadding={hasMessages ? undefined : 40}
        rowGap={hasMessages ? undefined : 32}
        fill={false}
        vAlign="center"
      >
        {isHistoryLoading ? (
          <>
            <Stack hPadding={24} hAlign="end">
              <Skeleton height={88} radius={16} width={200} />
            </Stack>
            <Stack hPadding={24} hAlign="start" vPadding={8}>
              <Skeleton height={120} radius={16} width="50ch" />
            </Stack>
            <Stack hPadding={24} hAlign="end">
              <Skeleton height={88} radius={16} width="30ch" />
            </Stack>
          </>
        ) : (
          <>
            {hasMessages ? (
              <ScrollArea
                as={Stack}
                hPadding={24}
                ref={refs}
                vPadding={24}
                canScroll={!isLoading}
                className={styles.Messages}
                fadeDirection="vertical"
                useSystemStyle={false}
                fill={false}
                thumbColor="var(--dimmed-4)"
              >
                <Stack
                  as="ul"
                  rowGap={16}
                  fill={false}
                  vAlign="start"
                  hAlign="end"
                >
                  <AnimatePresence>
                    {messages.map(m => (
                      <LumaBalloon
                        key={m.id}
                        author={m.role === 'user' ? 'You' : 'Wilson'}
                        isResponse={m.role !== 'user'}
                      >
                        {m.content}
                      </LumaBalloon>
                    ))}
                  </AnimatePresence>
                  <Separator />
                </Stack>

                {!isLoading && isLumaOpened && hasOrganizationChatTokens && (
                  <LumaPresets
                    presets={queryPresets}
                    visiblePresets={4}
                    presetsSize="small"
                    onClick={async query => append({ role: 'user', content: query })}
                    shouldShowAllPresets={shouldShowAllPresets}
                    onExpand={() => setShouldShowAllPresets(true)}
                  />
                )}

                {isShowJumpButton && (
                  <Stack hAlign="center" vPadding={8} className={styles.Jump}>
                    <Button
                      icon="arrow-down"
                      onClick={handleJumpToBottom}
                      dimension="small"
                      iconPosition="end"
                    >
                      {t('luma:messages.jumpDown')}
                    </Button>
                  </Stack>
                )}
              </ScrollArea>
            ) : (
              <Stack hPadding={24}>
                <InfoState
                  title={t('luma:infoState.title')}
                  icon="ai-chat"
                  iconColor="blue"
                  actions={!hasMessages && (
                    <Stack hPadding={24}>
                      {InfoStateActions}
                    </Stack>
                  )}
                >
                  {t('luma:infoState.text')}
                </InfoState>
              </Stack>
            )}
          </>
        )}

      </Stack>

      {hasOrganizationChatTokens ? (
        <Stack hPadding={24}>
          <LumaInput
            onSubmit={handleSubmit}
            onChange={handleInputChange}
            onStop={stop}
            value={input}
            disabled={isLoading}
            rows={hasMessages ? 1 : 7}
            loading={isLoading}
          />
        </Stack>
      ) : (
        <Stack hPadding={24} vPadding={24}>
          <LumaNoTokens />
        </Stack>
      )}

      <Stack hAlign="center" fill={false} vPadding={4} rowGap={8}>
        <Chip dimension="small" color="green">beta</Chip>
        <Text size={12} dimmed={4}>
          <LumaLogo />
          {' '}
          {t('luma:disclaimer')}
        </Text>
      </Stack>
    </Stack>
  ), [
    otherProps,
    hasMessages,
    isHistoryLoading,
    isLoading,
    messages,
    isLumaOpened,
    queryPresets,
    shouldShowAllPresets,
    isShowJumpButton,
    handleJumpToBottom,
    t,
    handleSubmit,
    handleInputChange,
    stop,
    input,
    append,
    setShouldShowAllPresets,
    hasOrganizationChatTokens,
    InfoStateActions,
    refs,
  ]);

  if (isLumaOpened && matches.extraLarge) {
    return (
      <Panel
        bordered
        radius={24}
        vibrant
        showGlow
        glowSpread={200}
        rainbowColors
        className={styles.ChatPanel}
      >
        {LumaCore}
      </Panel>
    );
  }

  return (
    <Drawer
      isOpen={isLumaOpened}
      onClose={() => setIsLumaOpened(false)}
      side="left"
      title={<LumaLogo dimension="big" />}
      maxWidth="700px"
    >
      {LumaCore}
    </Drawer>
  );
};

Luma.displayName = 'Luma';
