import { Message, useChat } from 'ai/react';
import {
  useEffect, useMemo, useState,
} from 'react';

import { useGetChatMessages } from '@/api/chat';
import { useGetTokensMonthUsed } from '@/api/chat/chat';
import { useFeatureLimits } from '@/api/organization';
import { getHeaders } from '@/services/apollo/get-headers';

import { useTranslate } from '../i18n';

export const useLumaLogic = ({
  userId,
  organizationId,
}: { userId: number; organizationId: number }) => {
  const { t } = useTranslate();
  const queryPresets: string[] = useMemo(() => t('luma:presets', {}, { returnObjects: true }), [t]);
  const [shouldShowAllPresets, setShouldShowAllPresets] = useState(false);
  const { chatMessages: historyMessages, loading: isHistoryLoading } = useGetChatMessages({
    userId,
    organizationId,
  });
  const { featureLimits } = useFeatureLimits();

  const { tokensMonthUsed, loading: isTokensMonthUsedLoading } = useGetTokensMonthUsed({
    organizationId,
  });

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    append,
    isLoading,
    stop,
    setMessages,
  } = useChat({
    api: '/api/ai/chat',
    body: {
      // TODO toti poi ne parliamo
      // pageContext,
      // deviceData
      userId,
      organizationId,
    },
    headers: {
      ...getHeaders(),
      'x-hasura-user-id': String(userId) ?? '',
    },
    experimental_throttle: 150,
  });

  const hasMessages = useMemo(() => messages.length > 0, [messages]);

  const hasOrganizationChatTokens = useMemo(() => (Number(featureLimits?.maxChatTokensMonth)
   - Number(tokensMonthUsed ?? 0)) > 0, [featureLimits,
    tokensMonthUsed]);

  const chatHistoryMap: Message[] = useMemo(
    () => historyMessages.map(({ id, isUserMessage, content }) => ({
      id: String(id),
      role: isUserMessage ? 'user' : 'assistant',
      content,
    })),
    [historyMessages],
  );

  useEffect(() => {
    if (chatHistoryMap.length > 0) {
      setMessages(chatHistoryMap);
    }
  }, [chatHistoryMap, setMessages]);

  return {
    hasMessages,
    messages,
    input,
    handleInputChange,
    handleSubmit,
    append,
    queryPresets,
    shouldShowAllPresets,
    setShouldShowAllPresets,
    isLoading,
    stop,
    isHistoryLoading,
    isTokensMonthUsedLoading,
    hasOrganizationChatTokens,
  };
};
