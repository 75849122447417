import {
  FCChildrenClass, Icon, Prose, Spinner, Stack, Text, Title,
} from '@lualtek/react-components';
import Markdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { CodeBlock } from '@/components/common/shared/code-block';

import styles from './balloon.module.css';

export type LumaBalloonProps = {
  author: string;
  isResponse?: boolean;
  children: string;
  isLoading?: boolean;
}

const markdownComponents: Components = {
  p(props) {
    return <Text {...props} as="p" size={16} responsive={false} />;
  },
  h1(props) {
    return <Title {...props} responsive={false} level="5" as="h1" />;
  },
  h2(props) {
    return <Title {...props} responsive={false} level="5" as="h2" />;
  },
  h3(props) {
    return <Title {...props} responsive={false} level="5" as="h3" />;
  },
  h4(props) {
    return <Title {...props} responsive={false} level="5" as="h4" />;
  },
  h5(props) {
    return <Title {...props} responsive={false} level="5" as="h5" />;
  },
  h6(props) {
    return <Title {...props} responsive={false} level="6" as="h6" />;
  },
  table(props) {
    return (
      <div className={styles.TableWrapper}>
        <table data-table-separators {...props} />
      </div>
    );
  },
  code(props) {
    const { children: code, className } = props;
    return <CodeBlock code={code} className={className} />;
  },
};

export const LumaBalloon: FCChildrenClass<LumaBalloonProps> = ({
  className,
  children,
  author,
  isResponse,
  isLoading,
  ...otherProps
}) => (
  <Stack
    as="li"
    direction="row"
    vPadding={8}
    columnGap={8}
    vAlign="start"
    hPadding={isResponse ? undefined : 16}
    data-chat-balloon-response={isResponse}
    className={styles.Balloon}
    {...otherProps}
  >
    {isResponse && <Icon source="ai-chat" data-shrink="0" dimension={18} fill="url(#DawnGradient)" />}
    <Prose as={Stack} gap="small" className={styles.MessageWrapper}>
      {(isLoading && isResponse) ? <Spinner /> : (
        <Markdown
          remarkPlugins={[remarkGfm]}
          components={markdownComponents}
        >
          {children}
        </Markdown>
      )}
    </Prose>
  </Stack>
);

LumaBalloon.displayName = 'LumaBalloon';
