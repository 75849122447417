import {
  FCClass, IconButton, Stack, Textarea,
  TextareaProps,
} from '@lualtek/react-components';
import clsx from 'clsx';
import {
  ChangeEvent, FormEvent, useCallback,
  useRef,
} from 'react';
import { useBoundingclientrect } from 'rooks';

import { useTranslate } from '@/core/i18n';

import styles from './input.module.css';

export type LumaInputProps = Partial<TextareaProps> & {
  maxHeight?: number;
  rows?: TextareaProps['rows'];
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  disabled?: boolean;
  onStop?: () => void;
  loading?: boolean;
}

export const LumaInput: FCClass<LumaInputProps> = ({
  className,
  maxHeight = 265,
  disabled,
  onInput,
  onSubmit,
  onStop,
  loading,
  ...otherProps
}) => {
  const { t } = useTranslate();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const rect = useBoundingclientrect(textareaRef);

  const handleInput = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onInput?.(e);
    if (textareaRef.current) {
      // Reset altezza
      textareaRef.current.style.height = 'auto';
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      // Imposta altezza dinamica
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [onInput, maxHeight]);

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    onSubmit?.(e);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  }, [onSubmit]);

  return (
    <Stack
      as="form"
      fill={false}
      vPadding={16}
      className={clsx(styles.Input, className)}
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (!loading && e.key === 'Enter' && !e.shiftKey && !disabled) {
          e.preventDefault();
          handleSubmit(e);
        }
      }}
    >
      <Textarea
        {...otherProps}
        label=""
        aria-label={t('luma:input.label')}
        placeholder={t('luma:input.label')}
        ref={textareaRef}
        onInput={handleInput}
        style={{
          height: rect ? rect?.height : 'auto',
        }}
      />
      <Stack direction="row" columnGap={8} fill={false} className={styles.SendButton}>
        {disabled && <IconButton icon="remove" kind="secondary" sentiment="warning" onClick={onStop} />}
        {!disabled && (
          <IconButton
            type="submit"
            icon="arrow-right"
            aria-label={t('luma:input.sendLabel')}
            disabled={disabled}
          />
        )}
      </Stack>
    </Stack>
  );
};

LumaInput.displayName = 'LumaInput';
