import { subDays } from 'date-fns';

import {
  useGetChatMessagesQuery,
  useGetTokensMonthUsedQuery,
} from '@/generated/graphql';
import { extractError } from '@/services/apollo/extract-error';

import { HookProps } from '../types';

type UseGetChatMessagesProps = {
  userId?: number;
  organizationId?: number;
  startDate?: string;
  endDate?: string;
}

// Now - 24 hours
const defaultStartDate = subDays(new Date(), 1).toISOString();
const defaultEndDate = new Date().toISOString();

export const useGetChatMessages = ({
  organizationId,
  userId,
  startDate,
  endDate,
}: UseGetChatMessagesProps, props?: HookProps) => {
  const { data, loading, error } = useGetChatMessagesQuery({
    variables: {
      userId: userId ?? 0,
      organizationId: organizationId ?? 0,
      startDate: startDate ?? defaultStartDate,
      endDate: endDate ?? defaultEndDate,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => props?.onError?.(extractError(error)),
    skip: !organizationId || !userId,
  });

  return {
    loading,
    chatMessages: data?.chatMessages ?? [],
    error,
  };
};

export const useGetTokensMonthUsed = ({
  organizationId,
}: UseGetChatMessagesProps, props?: HookProps) => {
  const { data, loading, error } = useGetTokensMonthUsedQuery({
    variables: {
      organizationId: organizationId ?? 0,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => props?.onError?.(extractError(error)),
    skip: !organizationId,
  });

  return {
    loading,
    tokensMonthUsed: data?.organizationsChatParams.at(0)?.tokensMonthUsed,
    error,
  };
};

// export const useGetChatMessagesApplicationLazy = (props?: HookProps) => {
//   const [getChatMessagesQuery, getChatMessagesData] = useGetChatMessagesApplicationLazyQuery({
//     onError: error => props?.onError?.(extractError(error)),
//   });

//   const getAreaLite = useCallback(async ({ applicationId }: { applicationId: number }) => getChatMessagesQuery({
//     variables: {
//       applicationId,
//     },
//   }), [getChatMessagesQuery]);

//   return {
//     getAreaLite,
//     chatMessages: getChatMessagesData.data?.chatMessages ?? [],
//     loading: getChatMessagesData.loading,
//     error: getChatMessagesData.error,
//   };
// };

// export const useChatMessagesSubs = ({
//   onData,
//   excludeFirstLoad = false,
// }: {
//   onData?: () => void;
//   excludeFirstLoad?: boolean;
// }) => {
//   const firstLoadRef = useRef(true);
//   const { appid } = useCurrentQuery();
//   const {
//     data,
//     loading,
//     error,
//   } = useChatMessagesApplicationSubscription({
//     variables: {
//       applicationId: appid,
//     },
//     skip: !appid,
//     onData: () => onData?.(),
//   });

//   const dataParsed = useMemo(() => data?.chatMessages, [data]);

//   useEffect(() => {
//     if (excludeFirstLoad && firstLoadRef.current && data) {
//       firstLoadRef.current = false;
//     }
//   }, [data, excludeFirstLoad]);

//   return {
//     loading,
//     chatMessages: excludeFirstLoad && firstLoadRef.current ? [] : dataParsed ?? [],
//     error,
//   };
// };
