import { useCallback, useMemo } from 'react';
import { Except } from 'type-fest';

import { useInfoContext } from '@/context/use-info-context';
import { OrganizationFeatureLimitsFragment, useOrganizationFeatureLimitsQuery } from '@/generated/graphql';

export const useFeatureLimits = () => {
  const { info } = useInfoContext();
  const { data, loading, error } = useOrganizationFeatureLimitsQuery({
    variables: {
      organizationId: info?.currentOrganizationId ?? 0,
    },
    fetchPolicy: 'cache-and-network',
    skip: !info?.currentOrganizationId,
  });

  const featureLimits = useMemo(() => data?.featureLimits[0] ?? null, [data?.featureLimits]);

  const isFeatureLimitReached = useCallback(
    (kind: keyof Except<OrganizationFeatureLimitsFragment, '__typename'>, currentCreated: number) => {
      const limit = Number(featureLimits?.[kind]);
      return limit === 0 || currentCreated >= limit;
    },
    [featureLimits],
  );

  return {
    loading,
    featureLimits,
    featureLimitsParsed: {
      analysisDisabled: Number(featureLimits?.analysis) === 0,
      preventionsDisabled: Number(featureLimits?.preventions) === 0,
      automationDisabled: Number(featureLimits?.automations) === 0,
      chatDisabled: Number(featureLimits?.maxChatTokensMonth) === 0,
    },
    isFeatureLimitReached,
    error,
  };
};
