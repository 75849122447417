import {
  Chip, ChipProps, Stack, Title,
} from '@lualtek/react-components';
import { AnimatePresence, m } from 'motion/react';
import { FC } from 'react';

import { useTranslate } from '@/core/i18n';

export type LumaPresetsProps = {
  presets: string[];
  visiblePresets?: number;
  shouldShowAllPresets?: boolean;
  presetsSize?: ChipProps['dimension'];
  onClick?: (query: string) => void;
  onExpand?: () => void;
}

export const LumaPresets: FC<LumaPresetsProps> = ({
  presets,
  visiblePresets = 4,
  shouldShowAllPresets,
  presetsSize = 'big',
  onClick,
  onExpand,
  ...otherProps
}) => {
  const { t } = useTranslate();
  return (
    <Stack rowGap={16} {...otherProps}>
      <Title align="center" level="6" as="h6">{t('luma:presetsTitle')}</Title>
      <Stack direction="row" fill={false} hAlign="center" vAlign="center" rowGap={4} columnGap={8} wrap>
        {presets.slice(0, visiblePresets).map(query => (
          <Chip
            key={query}
            color="brand"
            dimension={presetsSize}
            interactive
            onClick={() => onClick?.(query)}
          >
            {query}
          </Chip>
        ))}
        <AnimatePresence>
          {shouldShowAllPresets && presets.slice(visiblePresets, presets.length).map((query, index) => (
            <m.div
              key={query}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: index * 0.05 } }}
            >
              <Chip
                color="brand"
                interactive
                dimension={presetsSize}
                onClick={() => onClick?.(query)}
              >
                {query}
              </Chip>
            </m.div>
          ))}
        </AnimatePresence>

        {!shouldShowAllPresets && presets.length > visiblePresets && (
          <Chip icon="plus" interactive dimension={presetsSize} onClick={onExpand}>
            {t('common:glossary.loadMore')}
          </Chip>
        )}
      </Stack>
    </Stack>
  );
};

LumaPresets.displayName = 'LumaPresets';
