import {
  ClampText, Stack, Text,
  useResponsiveContext,
} from '@lualtek/react-components';
import { AnimatePresence, m } from 'motion/react';
import Link from 'next/link';
import { forwardRef, ReactNode } from 'react';
import { useWindowScrollPosition } from 'rooks';

import styles from './breadcrumbs.module.css';

type CrumbProps = {
  href: string;
  last?: boolean;
  title?: ReactNode;
  subtitle?: ReactNode;
}

const LinkOrFragment = forwardRef<HTMLAnchorElement, {
  children: ReactNode;
  href?: string;
  passHref?: boolean;
}>(({ children, href, passHref }, ref) => (
  href ? <Link ref={ref} href={href} passHref={passHref} legacyBehavior>{children}</Link> : <>{children}</>
));

export const Crumb: FCChildrenClass<CrumbProps> = ({
  href, last, title, subtitle,
}) => {
  const position = useWindowScrollPosition();
  const { matches } = useResponsiveContext();

  return (
    <AnimatePresence mode="popLayout">
      <LinkOrFragment href={!last ? href : undefined} passHref={!last}>
        <Stack
          as={last ? m.span : m.a}
          className={styles.Crumb}
          vAlign="center"
          inline
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <Stack as="span" vAlign="center" fill={false} vPadding={4} className={styles.Content}>
            <Text as={ClampText} size={14} rows={1} lineHeight="extra-small" whiteSpace="nowrap" responsive={false}>
              {title}
            </Text>
            <AnimatePresence mode="popLayout">
              {position.scrollY >= 130 && (
                <m.div
                  initial={{
                    y: 5,
                    opacity: 0,
                  }}
                  animate={{
                    y: 0,
                    opacity: 1,
                  }}
                  exit={{
                    y: 2,
                    opacity: 0,
                    transition: { duration: 0.1 },
                  }}
                >
                  {(last && subtitle && matches.wide) && subtitle}
                </m.div>
              )}
            </AnimatePresence>
          </Stack>
        </Stack>
      </LinkOrFragment>
    </AnimatePresence>
  );
};
