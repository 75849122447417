import {
  ClampText, FCClass, Icon, Panel, Stack, Text,
} from '@lualtek/react-components';
import clsx from 'clsx';
import dynamic from 'next/dynamic';

import { useApplications } from '@/api/application';
import { useUser } from '@/api/user';
import { WeatherProps } from '@/components/common/desktop/weather';
import { useMobileContext } from '@/context/use-mobile-context';
import { useTranslate } from '@/core/i18n';
import { useCurrentQuery } from '@/core/navigation/use-current-query';

import styles from './app-switcher.module.css';

export type AppSwitcherProps = {
  showWeather?: boolean;
  responsive?: boolean;
}

const Weather = dynamic<WeatherProps>(
  async () => import('@/components/common/desktop/weather').then(mod => mod.Weather), {
    loading: () => <>Loading meteo</>,
  },
);

export const AppSwitcher: FCClass<AppSwitcherProps> = ({
  className,
  showWeather = true,
  responsive = true,
  ...otherProps
}) => {
  const { t } = useTranslate();
  const { user } = useUser();
  const { currentApplication } = useApplications({ userId: user?.id });
  const { appid } = useCurrentQuery();
  const { isMobile } = useMobileContext();

  return (
    <>
      {currentApplication && (
        <Panel
          bordered
          radius={16}
          showGlow
          className={clsx(styles.SelectedBanner, className)}
          {...otherProps}
        >
          <Stack
            direction="row"
            hPadding={isMobile ? 16 : 16}
            vPadding={isMobile ? 16 : 8}
            hAlign="space-between"
            fill={false}
            columnGap={32}
            vAlign="center"
          >
            <Stack>
              <Text size={14} whiteSpace="nowrap">{t('common:glossary.currentApplication')}</Text>
              <Text size={isMobile ? 18 : 16} weight="bold" dimmed={8} lineHeight="extra-small">
                <ClampText rows={2}>{currentApplication.name}</ClampText>
              </Text>
            </Stack>

            <Stack direction="row" hAlign="center" columnGap={16}>
              <Stack
                className={clsx((!isMobile && responsive) && styles.Meta)}
                direction="row"
                vAlign="center"
                columnGap={4}
              >
                <Icon dimension={18} fill="url(#DawnGradient)" source="device" />
                <Text size={14} whiteSpace="nowrap">{currentApplication.devicesCount}</Text>
              </Stack>
              <Stack
                className={clsx((!isMobile && responsive) && styles.Meta)}
                direction="row"
                vAlign="center"
                columnGap={4}
              >
                <Icon dimension={18} fill="url(#DawnGradient)" source="decentralize" />
                <Text size={14} whiteSpace="nowrap">{currentApplication.automationsCount}</Text>
              </Stack>
              {showWeather && <Weather className={styles.WeatherWidget} applicationId={appid} collapsed />}
            </Stack>
          </Stack>
        </Panel>
      )}
    </>
  );
};

AppSwitcher.displayName = 'AppSwitcher';
