
import DOMPurify from 'dompurify';
import { useTheme } from 'next-themes';
import {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import type { BundledLanguage } from 'shiki';
import { codeToHtml } from 'shiki';

import styles from './code-block.module.css';

type CodeBlockProps = {
  code: ReactNode;
  language?: BundledLanguage;
  className?: string;
};

export const CodeBlock = ({
  code,
  language = 'markdown',
  className,
}: CodeBlockProps) => {
  const [html, setHtml] = useState<string>('');
  const { resolvedTheme } = useTheme();

  const languageName = useMemo(() => /language-(\w+)/.exec(className ?? ''), [className]);

  const generateHtml = useCallback(async () => {
    const html = await codeToHtml(typeof code === 'string' ? code.trim() : '', {
      lang: languageName ? languageName[1] : language,
      themes: { dark: 'min-dark', light: 'min-light' },
      defaultColor: resolvedTheme === 'dark' ? 'dark' : 'light',
    });
    const sanitizedHTML = DOMPurify.sanitize(html);
    setHtml(sanitizedHTML);
  },
  [code, language, resolvedTheme, languageName]);

  useEffect(() => {
    void generateHtml();
  }, [generateHtml]);

  return (
    <div
      className={styles.Code}
      // eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
