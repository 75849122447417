'use client';

import { FCClass } from '@lualtek/react-components';
import { clsx } from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import {
  useEffect, useMemo, useState,
} from 'react';

import styles from './sparks.module.css';

export type SparksProps = {
  active?: boolean;
  /**
   * Define the size of the area where the spark can move on the x-axis
   *
   * @defaultValue 48
   */
  areaSizeX?: number;
  /**
   * Define the size of the area where the spark can move on the y-axis
   *
   * @defaultValue 48
   */
  areaSizeY?: number;
  /**
   * Define the maximum size of the spark
   *
   * @defaultValue 16
   */
  maxSparkSize?: number;
  /**
   * Define the minimum size of the spark
   *
   * @defaultValue 8
   */
  minSparkSize?: number;
  /**
   * Define the colors of the sparks, must contain at least one value
   *
   * @defaultValue ['var(--highlight-cyan-foreground)', 'var(--highlight-purple-foreground)', 'white']
   */
  sparksColors?: [string, ...string[]];
};

export const Sparks: FCClass<SparksProps> = ({
  active = true,
  areaSizeX = 48,
  areaSizeY = 48,
  maxSparkSize = 16,
  minSparkSize = 8,
  sparksColors = ['var(--highlight-cyan-foreground)', 'var(--highlight-purple-foreground)', 'white'],
  className,
  ...otherProps
}) => {
  const [position, setPosition] = useState({
    x: Math.floor(Math.random() * (areaSizeX + 1)),
    y: Math.floor(Math.random() * (areaSizeY + 1)),
  });

  const [size, setSize] = useState({
    width: Math.floor(Math.random() * (areaSizeX)),
    height: Math.floor(Math.random() * (areaSizeY)),
  });

  const [color, setColor] = useState(sparksColors[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition({
        x: Math.floor(Math.random() * (areaSizeX + 1)),
        y: Math.floor(Math.random() * (areaSizeY + 1)),
      });
      setSize({
        width: Math.floor(Math.random() * (areaSizeX)),
        height: Math.floor(Math.random() * (areaSizeY)),
      });
      setColor(sparksColors[Math.floor(Math.random() * sparksColors.length)]);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [active, areaSizeX, areaSizeY, sparksColors]);

  const scaleKeyframes = [0, 1, 0];
  const rotateKeyframes = [0, 180];

  const svgVariants = {
    hidden: {
      opacity: 0,
      rotate: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      rotate: rotateKeyframes,
      scale: scaleKeyframes,
      transition: {
        duration: 1,
        repeat: Infinity,
      },
    },
  };

  const svgStyle = useMemo(() => ({
    position: 'absolute',
    width: `clamp(${minSparkSize}px, ${size.width}px, ${maxSparkSize}px)`,
    height: `clamp(${minSparkSize}px, ${size.height}px, ${maxSparkSize}px)`,
    top: `${position.y}px`,
    left: `${position.x}px`,
  }), [maxSparkSize, minSparkSize, position.x, position.y, size.height, size.width]);

  const computedStyle = useMemo(() => ({
    width: areaSizeX,
    height: areaSizeY,
  }), [areaSizeX, areaSizeY]);

  return (
    <span style={computedStyle} className={clsx(styles.Sparks, className)} {...otherProps}>
      <AnimatePresence>
        {active && (
          <motion.svg
            key={position.x + position.y}
            width="100"
            height="100"
            viewBox="0 0 512 512"
            style={svgStyle}
            variants={svgVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <motion.path
          // eslint-disable-next-line max-len
              d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z"
              fill={color}
            />
          </motion.svg>
        )}
      </AnimatePresence>
    </span>
  );
};
