import {
  Icon, IconProps, Stack, Text, TextProps,
} from '@lualtek/react-components';

export type LumaLogoProps = {
  dimension?: 'small' | 'regular' | 'big';
}

type SizesType = Record<NonNullable<LumaLogoProps['dimension']>, {
  text: TextProps['size'];
  icon: IconProps['dimension'];
}>;

const sizes: SizesType = {
  small: {
    text: 12,
    icon: 12,
  },
  regular: {
    text: 16,
    icon: 16,
  },
  big: {
    text: 22,
    icon: 24,
  },
};

export const LumaLogo: FCClass<LumaLogoProps> = ({
  className,
  dimension = 'small',
  ...otherProps
}) => (
  <Stack
    as={Text}
    inline
    vAlign="center"
    direction="row"
    columnGap={2}
    size={sizes[dimension].text}
    className={className}
    responsive={false}
    {...otherProps}
  >
    <b>Luma</b>
    <Icon source="ai-chat" dimension={sizes[dimension].icon} fill="url(#DawnGradient)" />
  </Stack>
);

LumaLogo.displayName = 'LumaLogo';
