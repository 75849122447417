import constate from 'constate';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { STORAGE_KEY_LUMA } from '@/services/local-storage/keys';

export type CurrentPageContext = {
  pageName: string;
  keyElements: string[];
  availableActions: string[];
  userGoals: string[];
};

const mapInfoByPathname: Record<string, CurrentPageContext> = {
  '/app/[appid]/dashboard': {
    pageName: 'Dashboard',
    keyElements: [],
    availableActions: [],
    userGoals: ['Overview of most used devices', 'Overview of most used analyses', 'Overview of automations'],
  },
  '/app/[appid]/areas/[areaid]/[deviceid]': {
    pageName: 'Device detail',
    keyElements: [],
    availableActions: [],
    userGoals: ['Device data detail chart by timeframe'],
  },
};

type DeviceData = {
  deviceName: string;
  sampleData: Array<Record<string, string | number>>;
}

const useLuma = () => {
  const router = useRouter();
  const [pageContext, setPageContext] = useState<CurrentPageContext>();
  const [deviceData, setDeviceData] = useState<DeviceData>();
  const [isLumaOpened, setIsLumaOpened] = useLocalstorageState<boolean>(STORAGE_KEY_LUMA, false);

  useEffect(() => {
    if (mapInfoByPathname[router.pathname]) {
      setPageContext(mapInfoByPathname[router.pathname]);
    }
  }, [router.pathname]);

  return {
    pageContext,
    setPageContext,
    deviceData,
    setDeviceData,
    isLumaOpened,
    setIsLumaOpened,
  };
};

export const [
  LumaContextProvider,
  useLumaContext,
] = constate(useLuma);
