import {
  BlankButton,
  ConditionalWrapper,
  Container,
  Icon,
  Logo,
  Panel, Stack, StickySpy,
  Text,
} from '@lualtek/react-components';
import clsx from 'clsx';
import { m } from 'motion/react';
import getConfig from 'next/config';

import { useApplications } from '@/api/application';
import { useFeatureLimits } from '@/api/organization';
import { useUser } from '@/api/user';
import { AppSwitcher } from '@/components/common/desktop/app-switcher';
import { ApplicationModal } from '@/components/common/shared/application-modal';
import { Breadcrumbs } from '@/components/common/shared/breadcrumbs';
import { Sidebar } from '@/components/common/shared/sidebar';
import { Sparks } from '@/components/common/shared/sparks';
import { BaseLayout } from '@/components/layouts/shared/base-layout';
import { Luma } from '@/components/luma/desktop/chat';
import { useApplicationModalContext } from '@/context/use-application-modal-context';
import { useLumaContext } from '@/context/use-luma-context';
import { useTranslate } from '@/core/i18n';

import { CommonLayoutType } from '../../types';
import styles from './shell-layout.module.css';

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: { version: string } };

const sidebarAnimation = {
  hidden: {
    opacity: 0,
    height: '100%',
  },
  visible: {
    opacity: 1,
    height: '100%',
  },
};

export const ShellLayout: CommonLayoutType = ({
  children,
  className,
  ...otherProps
}) => {
  const { user } = useUser();
  const { t } = useTranslate();
  const { isLumaOpened, setIsLumaOpened } = useLumaContext();
  const {
    openApplicationModal,
  } = useApplicationModalContext();
  const { applications, currentApplication } = useApplications({ userId: user?.id });
  const { featureLimitsParsed } = useFeatureLimits();

  return (
    <BaseLayout>
      <a className={styles.SkipToContent} href="#maincontent">{t('common:cta.skipToContent')}</a>
      <main
        className={clsx(styles.ShellLayout, className)}
        data-is-chat-open={featureLimitsParsed.chatDisabled ? undefined : isLumaOpened}
        {...otherProps}
      >
        <m.div
          className={styles.Shape}
          initial={{ x: -400, rotate: -90 }}
          animate={{ opacity: 0.3, x: 0, rotate: 0 }}
          transition={{
            type: 'spring',
            stiffness: 100,
            damping: 30,
          }}
        />
        <Panel as="header" showGlow radius={24} vibrant bordered className={styles.Header}>
          <Stack
            vAlign="start"
            fill={false}
            vPadding={16}
          >
            {featureLimitsParsed.chatDisabled ? (
              <Stack
                fill={false}
                as={m.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={styles.LogoContainer}
                data-theme="dark"
                transition={{
                  type: 'spring',
                  stiffness: 100,
                  damping: 30,
                }}
              >
                <Logo height="56" variant="mark" />
              </Stack>
            ) : (
              <BlankButton
                onClick={() => setIsLumaOpened(state => !state)}
                data-theme="dark"
                className={styles.LogoContainer}
              >
                <Sparks sparksColors={isLumaOpened ? ['white'] : undefined} className={styles.Shiny} />
                <Icon source="ai-chat" dimension={32} fill={isLumaOpened ? 'white' : 'url(#DawnGradient)'} />
              </BlankButton>
            )}

            <Stack
              as={m.div}
              initial="hidden"
              animate="visible"
              variants={sidebarAnimation}
              transition={{
                type: 'spring',
                stiffness: 100,
                damping: 30,
              }}
            >
              <Sidebar />
            </Stack>

            <Stack
              as={m.div}
              direction="row"
              fill={false}
              hAlign="center"
              vAlign="center"
              columnGap={8}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              variants={sidebarAnimation}
              transition={{
                type: 'spring',
                stiffness: 100,
                damping: 30,
              }}
            >
              <Text responsive={false} align="center" dimmed={4} size={14}>{publicRuntimeConfig.version}</Text>
            </Stack>
          </Stack>
        </Panel>

        {featureLimitsParsed.chatDisabled ? null : <Luma />}

        <Panel
          bordered
          radius={24}
          className={styles.View}
          id="maincontent"
        >
          <StickySpy>
            <Container className={styles.Toolbar}>
              <Stack
                direction="row"
                vPadding={24}
                columnGap={24}
                rowGap={24}
                hAlign="space-between"
                vAlign="center"
                as={m.div}
                fill={false}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 100,
                  damping: 30,
                }}
              >
                <div>
                  <Breadcrumbs />
                </div>

                <ConditionalWrapper
                  condition={applications.length > 1}
                  wrapper={children => <BlankButton onClick={openApplicationModal}>{children}</BlankButton>}
                >
                  <AppSwitcher />
                </ConditionalWrapper>
              </Stack>
            </Container>
          </StickySpy>

          <Container>
            {children}
          </Container>
        </Panel>
        <ApplicationModal
          applications={applications}
          currentApp={currentApplication}
        />
      </main>
    </BaseLayout>
  );
};
